@import "../../app.css";

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header__banner {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header__logo {
  width: 100px;
}

.header__title {
  font-family: Noteworthy, cursive;
  font-weight: bold;
  font-size: 2em;
  text-align: center;
  padding: 1rem;
}

.header__loggedIn {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header__username {
  cursor: pointer;
}

.header__btn-new-post,
.header__btn-new-post:visited {
  background-color: var(--dark-prim-color);
  color: var(--light-color);
  text-decoration: none;
  border-radius: 5px;
  padding: 10px 20px;
  transition: all 0.1s ease-in;
}

.header__btn-new-post:hover {
  background-color: var(--light-prim-color);
  color: var(--light-color);
  cursor: pointer;
  scale: 1.1;
  transition: all 0.1s ease-in;
}

.header__navbar {
  margin: 2rem 0;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.header__category {
  display: none;
}

.header__category-dropdown {
  position: relative;
  display: flex;
  text-align: center;
  width: min-content;
  background-color: transparent;
  padding: 1rem 0;
}

.category-select {
  font-family: 'Aleo', sans;
  display: flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: var(--black-color);
  font-size: 1rem;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--dark-prim-color);
  border-radius: 0;
  padding: 5px;
  padding-right: 2rem;
}

.category-select__icon {
  position: absolute;
  pointer-events: none;
  font-size: 1rem;
  padding: 5px;
  top: 50%;
  right: 0;
  transform: translateY(-50%) rotate(180deg);
}

.header__link.active {
  padding: 0 20px;
  color: var(--black-color);
  text-decoration: none;
  border-bottom: 2px solid var(--dark-prim-color);
  border-radius: 0;
}


.header__about {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  align-items: center;
  border-bottom: 2px solid transparent;
  transition: all 0.1s ease-in;
}

.header__about-btn {
  color: inherit;
  padding: 5px 1rem;
  text-decoration: none;
  border: 1px solid var(--dark-prim-color);
  border-radius: 5px;
  transition: all 0.1s ease-in;
}

.header__about-btn:visited {
  color: inherit;
}

.header__about-btn:hover {
  background-color: var(--light-prim-color);
  border: 1px solid var(--light-prim-color);
  color: var(--light-color);
  transition: all 0.1s ease-in;
}

.header__linkedIn-btn svg, .header__email-btn svg {
  display: block;
  fill: var(--dark-prim-color);
  width: 32px;
  height: auto;
  transition: all 0.1s ease-in;
  margin: 0;
  padding: 0;
}

.header__linkedIn-btn svg {
  width: 27px;
}

.header__linkedIn-btn svg:hover, .header__email-btn svg:hover {
  fill: var(--light-prim-color);
  scale: 1.1;
  transition: all 0.1s ease-in;
}

@media only screen and (min-width: 768px) /* and (max-width: 991px) */ {
  /* Estilos para tabletas */
  .header__logo {
    position: absolute;
    top: 0;
    left: 0;
  }
  .header__banner {
    justify-content: center;
  }

  .header__title {
    font-size: 3.5em;
    margin: 2rem;
    padding: 0 4rem;
  }

  .header__loggedIn {
    position: absolute;
    margin-top: 0;
    right: 0;
    padding-right: 1rem;
    gap: 20px;
  }

  .header__navbar {
    font-size: 1.2rem;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin: 0;
    border-bottom: 1px solid var(--dark-prim-color);
  }

  .header__category {
    display: flex;
    height: 100%;
    text-align: center;
    align-items: flex-end;
  }

  .header__category-dropdown {
    display: none;
  }

  .header__link {
    padding: 0 20px;
    color: inherit;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    transition: all 0.1s ease-in;
  }

  .header__link:hover, .header__link-active:hover {
    cursor: pointer;
    border-bottom: 2px solid var(--dark-prim-color);
    transition: all 0.1s ease-in;
  }

  .header__link:visited {
    color: inherit;
  }

  .header__about {
    flex-direction: row;
  }

  .header__about-btn {
    padding: 0 1rem;
  }
}

@media only screen and (min-width: 992px) {
  /* Estilos para computadoras de escritorio */
}

@media only screen and (min-width: 1200px) {
  /* Estilos para pantallas extra grandes */
}
