.login {
  width: 100vw;
  display: flex;
  justify-content: center;
  background: var(--light-color);
}

.login__container {
  margin: 1rem;
  padding: 1rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--dark-prim-color);
  border-radius: 5px;
}

.login__title {
  padding: 1rem;
}

.login__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login__username, .login__password {
  font-size: 1rem;
  background-color: transparent;
  width: 100%;
  padding: 0.5rem;
  margin: 0.5rem;
  border: 1px solid transparent;
  border-bottom: 1px solid var(--dark-prim-color);
  border-radius: 0;
}

.login__btn {
  font-size: 1rem;
  width: 100%;
  padding: 0.5rem;
  margin: 0.5rem;
  border: none;
  border-radius: 5px;
  background-color: var(--dark-prim-color);
  color: var(--light-color);
  transition: background-color .1s ease-in;
}

.login__btn:hover {
  background-color: var(--light-prim-color);
  cursor: pointer;
  transition: background-color .1s ease-in;
}

.login__return {
  text-align: center;
  width: 100%;
  padding: 0.5rem;
  margin: 0.5rem;
  transition: color .1s ease-in;
}

.login__return a {
  text-decoration: none;
}

.login__return a:visited {
  color: inherit;
  transition: color .1s ease-in;
}

.login__return a:hover {
  color: var(--light-prim-color);
  transition: color .1s ease-in;
}

.login__error {
  color: red;
  margin-top: .5rem;
  padding-top: .5rem;
}