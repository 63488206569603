.author__information {
  display: flex;
  align-items: center;
  padding: 1rem;
  padding-top: 0;
}

.author__avatar {
  width: 4rem;
  padding: 0.4rem;
  border: 1px solid var(--dark-prim-color);
  border-radius: 50%;
}

.author__name {
  font-size: 1.2rem;
  padding-left: 1rem;
}

.author__data {
  display: flex;
  flex-direction: column;
}

.author__date {
  font-size: 1rem;
  padding-left: 1rem;
}

.single-post :not(h1) {
  max-width: 800px;
  list-style-position: inside;
  list-style-image: initial;
}

.single-post h1 {
  margin: 1rem auto;
}

.single-post img {
  display: block;
  width: 100%;
  height: auto;
}

.single-post p, .single-post ul, .single-post ol {
  font-size: 1.2rem;
  line-height: 2rem;
}

.single-post iframe {
  max-width: 100%;
}

.ql-size-small {
  font-size: 1rem !important;
}

pre {
  background-color: var(--dark-prim-color);
  color: var(--light-color);
  padding: 15px;
  border-radius: 5px;
}

code::before,
code::after {
  content: "'";
}

ol, ul {
  margin-left: 1rem;
}

li:not(:first-child) {
  margin-top: 1rem;
}

li::marker {
  font-weight: bold;
}

@media only screen and (min-width: 767px) {
  /* Estilos para pantallas pequeñas */
}
