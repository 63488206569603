@import "../../app.css";

.post {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  border-bottom: 1px solid var(--black-color);
  scale: 1;
  transition: scale 0.1s ease-in;
}

.post:hover {
  scale: 1.01;
  transition: scale 0.1s ease-in;
  cursor: pointer;
}

.post__img-container {
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.post__img {
  max-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.post__container {
  padding: 1rem;
}

.post__title {
  margin: 1rem 0;
}

.post__btn {
  color: var(--dark-prim-color);
  font-size: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.post__btn svg {
  stroke: var(--dark-prim-color);
  transition: transform 0.3s ease-in-out;
}

.post:hover svg{
  transform: translate(2px, -2px);
}

@media only screen and (min-width: 767px) {
  
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  /* Estilos para tabletas */
}

@media only screen and (min-width: 992px) {
  /* Estilos para computadoras de escritorio */
}

@media only screen and (min-width: 1200px) {
  /* Estilos para pantallas extra grandes */
}
