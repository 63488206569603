@import "../../app.css";

.footer {
  background-color: var(--dark-prim-color);
  color: var(--light-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  font-size: 0.9rem;
}

.footer__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  flex: 1;
}

.footer__link {
  color: inherit;
  opacity: .8;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 20px;
}

.footer__link:visited {
  color: inherit;
}

.footer__container span {
  opacity: 0.8;
}

.footer svg {
  fill: var(--light-color);
  width: 1.5rem;
  height: 1.5rem;
}

.footer__author {
  color: inherit;
  opacity: .8;
  text-decoration: none;
  width: fit-content;
  border: 1px solid var(--light-color);
  border-radius: 5px;
  padding: .2rem .4rem;
}

.footer__author a {
  text-decoration: none;
  color: inherit;
}

@media only screen and (min-width: 768px) {
  .footer {
    flex-direction: row;
  }
}
