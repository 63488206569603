.main__posts {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 2rem;
}

.main__post {
  text-decoration: none;
  color: inherit;
  margin-bottom: 2rem;
  height: fit-content;
}
