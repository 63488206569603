.new-post {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.new-post__editor {
  width: 100%;
  max-width: 800px;
}

.quill {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ql-toolbar.ql-snow {
  border: 1px solid var(--dark-prim-color);
  border-radius: 5px 5px 0 0;
}

.ql-container.ql-snow {
  border: 1px solid var(--dark-prim-color);
  border-radius: 0 0 5px 5px;
  flex: 1;
  font-size: 1rem;
}

.new-post__aside {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.new-post__category {
  padding: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--dark-prim-color);
  border-radius: 5px;
}

.new-post__category h2 {
  margin: 10px;
}

.new-post__category label {
  margin: 2px 10px;
  display: flex;
  align-items: center;
}

.new-post__category--radio {
  width: 15px;
  height: 15px;
  margin: 5px 10px;
}

.new-post__category--input {
  margin: 5px 10px;
  padding: 5px 10px;
  width: 60%;
}

.new-post__btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.new-post__btns--publish,
.new-post__btns--draft {
  background-color: var(--dark-prim-color);
  color: var(--light-color);
  border: 1px solid transparent;
  border-radius: 5px;
  font-size: 1rem;
  padding: 10px 20px;
  transition: all 0.1s ease-in;
}

.new-post__btns--draft {
  background-color: transparent;
  border: 1px solid var(--dark-prim-color);
  border-radius: 5px;
  color: inherit;
  transition: all 0.1s ease-in;
}

.new-post__btns button:hover {
  background-color: var(--light-prim-color);
  cursor: pointer;
  transition: all 0.1s ease-in;
}

.new-post__btns--draft:hover {
  color: var(--light-color);
  border-color: transparent;
  transition: all 0.1s ease-in;
}
