@import "../../app.css";;

.about__article {
  max-width: 800px;
  margin: 0 auto;
}

.about__title {
  font-size: 2rem;
}

.about__img-container {
  margin: 2rem auto;
  max-width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 1px solid var(--dark-prim-color);
  overflow: hidden;
}

.about__img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center top;
}

.about__p {
  font-size: 1.2rem;
}